import React, { useState, useEffect } from "react";
import { ApplicationStatusText } from "../../types/Application";
import { isClockExpired } from "../../utils/HoldClock";
import StatusTag from "./StatusTag";

interface Props {
  remainingTime?: number | undefined | null;
  isDocumentEnabled?: boolean | false;
  status: ApplicationStatusText | "Expired";
}

const HoldClockStatus = ({
  remainingTime,
  isDocumentEnabled,
  ...props
}: Props) => {
  const [status, setStatus] = useState(props.status);

  useEffect(() => {
    if (
      typeof remainingTime !== "number" ||
      (typeof remainingTime === "number" && isClockExpired(remainingTime))
    )
      return;
    const timerId = setTimeout(() => {
      setStatus("Expired");
    }, remainingTime);
    return () => {
      return clearTimeout(timerId);
    };
  }, [remainingTime]);
  console.log("status", status);
  return <StatusTag name={status} isDocumentEnabled={isDocumentEnabled} />;
};

export default HoldClockStatus;
